import { useState, useEffect } from 'react';
import axios from 'axios';
import ENV from '../env.json';
import GA_MEASUREMENT_ID from '../constants/ga-measurement-id';

const localStorage: any =
  typeof window !== 'undefined' ? (window as any).localStorage : {};

const gtag = function (...args: any[]) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
  }
  (window as any).dataLayer.push(args);
  console.log(args);
};

const toBoolean = (value: string | undefined) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

export function useGdpr() {
  const [isEu, setEu] = useState(toBoolean(localStorage.isEu));
  const [acceptedCookies, setAcceptedCookies] = useState<boolean | undefined>(
    toBoolean(localStorage.acceptedCookies)
  );

  useEffect(() => {
    if (localStorage.isEu === 'true') {
      return;
    }

    axios.get(`${ENV.cloudFunctionsDomain}/geo-checkEu`).then(({ data }) => {
      setEu(data.isEu);
      localStorage.isEu = data.isEu;
    });
  }, []);

  useEffect(() => {
    // By default, these things are set to anonymize_ip true and
    // allowAdFeatures false. But if the user opts in and out again,
    // we need to handle re-setting them back
    if (acceptedCookies) {
      gtag('config', GA_MEASUREMENT_ID, {
        anonymize_ip: false,
        allowAdFeatures: true
      });
      try {
        (window as any).clarity('consent');
      } catch (err) {
        // do nothing
      }
      const script = document.createElement('script');
      script.src =
        'https://tag.clearbitscripts.com/v1/pk_0d0cd8b4d619e705a835cc85052b34ac/tags.js';
      script.async = true;
      script.setAttribute('referrer-policy', 'strict-origin-when-cross-origin');
      document.body.appendChild(script);
    } else {
      gtag('config', GA_MEASUREMENT_ID, {
        anonymize_ip: true,
        allowAdFeatures: false
      });
    }

    localStorage.acceptedCookies = acceptedCookies;
  }, [acceptedCookies]);

  return { isEu, acceptedCookies, setAcceptedCookies };
}
