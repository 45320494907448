import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import ENVS from '../../env.json';
import { useGdpr } from '../../hooks/useGdpr';
import styled from '../../styled';
import { WebinarForm } from '../WebinarForm';
import { sendCtaClickEvent } from '../../tracking';
import { CALENDAR_LINK } from '../../constants/demo-link';

const Content = styled(Typography)``;

const CompanyName = styled('span')`
  font-size: 12px;
  opacity: 0.8;
`;

const FooterWrapper = styled('div')`
  font-size: 18px;
  line-height: 32px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(8)}px
    ${(p) => p.theme.spacing(10)}px;
  background-color: #fff;
  color: #000;

  @media (max-width: 800px) {
    margin: 0;
    padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(1)}px;
  }

  svg {
    width: 100%;
  }
`;

const Inner = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  margin: 0 auto;
  padding: ${(p) => p.theme.spacing(2)}px;
  color: ${(p) => p.theme.palette.grey[200]};

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const List = styled('ul')`
  list-style-type: none;
  font-size: 16px;
  line-height: 36px;
  padding: 0;
  margin-top: 8px;

  li {
    margin-bottom: 4px;
  }

  a {
    color: ${(p) => p.theme.palette.grey[700]};

    &:hover {
      color: ${(p) => p.theme.palette.grey[900]};
    }
  }
`;

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  grid-column-gap: 24px;
  border-radius: 8px;

  @media (max-width: 900px) {
    margin-top: 1rem;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

const ListHeading = styled('div')`
  color: #000;
  font-weight: 700;

  @media (max-width: 500px) {
    margin-top: 1rem;
  }
`;

const Title = styled(Link)`
  display: flex;
  align-items: center;

  img {
    max-width: 200px;
    margin-right: 12px;
  }
`;

const LinkList = styled('ul')`
  color: ${(p) => p.theme.palette.grey[900]};
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 13px;

  li {
    display: inline-block;
    padding: 0;
    margin-right: ${(p) => p.theme.spacing(3)}px;

    a {
      display: inline-block;
      color: ${(p) => p.theme.palette.grey[400]};

      &:hover {
        color: ${(p) => p.theme.palette.grey[200]};
      }
    }
  }
`;

const EndFooter = styled('div')`
  margin-top: 50px;
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  border-top: 1px solid ${(p) => p.theme.palette.grey[100]};
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Footer = () => {
  const { isEu, setAcceptedCookies } = useGdpr();
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const showPrivacySettingsButton = isEu;

  return (
    <div>
      <Dialog
        open={showPrivacyDialog}
        onClose={() => {
          setShowPrivacyDialog(false);
        }}
      >
        <DialogTitle>Adjust your privacy settings</DialogTitle>
        <DialogContent>
          <Content>
            This website uses cookies for analytics and advertising. Learn more
            in our <Link to="/privacy-policy/">privacy policy</Link>.
          </Content>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAcceptedCookies(false);
              setShowPrivacyDialog(false);
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAcceptedCookies(true);
              setShowPrivacyDialog(false);
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <WebinarForm />
      <FooterWrapper>
        <Inner>
          <Wrapper>
            <div>
              <ListHeading>Platform</ListHeading>
              <List>
                <li>
                  <Link to="/product/">Platform features</Link>
                </li>
                <li>
                  <Link to="/pricing/">Pricing</Link>
                </li>
              </List>
            </div>
            <div>
              <ListHeading>Resources</ListHeading>
              <List>
                <li>
                  <Link to="/blog/">Blog</Link>
                </li>
                <li>
                  <Link to="/qs/faqs/">Pre-signup FAQs</Link>
                </li>
                <li>
                  <a href={ENVS.app.baseUrl} target="_blank">
                    Log in
                  </a>
                </li>
              </List>
            </div>
            <div>
              <ListHeading>Company</ListHeading>
              <List>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/partners/">Partners</Link>
                </li>
                <li>
                  <Link to="/brand/">Brand Assets</Link>
                </li>
                <li>
                  <Link to="/jobs/">Careers</Link>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
              </List>
            </div>
            <div>
              <ListHeading>Connect with us</ListHeading>
              <List>
                <li>
                  <a
                    href="https://www.linkedin.com/company/affilimate/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Follow on LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/groups/affilimate/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creators
                  </a>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <Button
                    href={CALENDAR_LINK}
                    variant="text"
                    target="_blank"
                    onClick={() => {
                      sendCtaClickEvent({ label: 'footer' });
                    }}
                    style={{ color: '#1890ff', padding: '0' }}
                  >
                    Book a demo
                  </Button>
                </li>
              </List>
            </div>
          </Wrapper>
        </Inner>
        <EndFooter>
          <Title to="/">
            <img
              style={{ maxWidth: '100px' }}
              src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
              alt="Affilimate"
              width="100"
              height="22"
              loading="lazy"
            />
            <CompanyName>By Adaero Software</CompanyName>
          </Title>
          <LinkList>
            <li>Copyright &copy; 2024</li>
            <li>
              <Link to="/impressum/">Imprint</Link>
            </li>
            <li>
              <Link to="/qs/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              {showPrivacySettingsButton && (
                <Button
                  style={{
                    color: '#bdbdbd',
                    fontSize: '13px',
                    padding: '0 0 3px 0'
                  }}
                  onClick={() => setShowPrivacyDialog(true)}
                >
                  Privacy settings
                </Button>
              )}
            </li>
          </LinkList>
        </EndFooter>
      </FooterWrapper>
    </div>
  );
};

export default Footer;
